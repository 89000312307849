<script setup lang="ts">
const styles = useCssModule()

// マスター設定
const { data: settingData, error: settingError } =
  await useSettingForPageBuild()
if (settingError.value) {
  console.error(settingError.value)
  throw settingError.value
}
const setting = computed(() => settingData.value)
</script>

<template>
  <template v-if="setting">
    <footer :class="styles.footer">
      <img
        v-if="setting.templateLayout.footerLogo"
        :class="styles.footer__logo"
        :src="setting.templateLayout.footerLogo"
        :alt="setting.companyName"
      />
      <ul :class="styles.footer__menu">
        <li
          v-for="(footer, index) in setting?.templateLayout.footers"
          :key="index"
        >
          <NuxtLink :to="footer.url" :class="styles.footer__menu__container">
            <div :class="styles.footer__menu__label">{{ footer.label }}</div>
            <div :class="styles.footer__menu__icon">
              <KsIcon name="arrowRight" color="#FFF" />
            </div>
          </NuxtLink>
        </li>
      </ul>
      <small :class="styles.footer__copyright">{{
        setting.templateLayout.copyright
      }}</small>
    </footer>
  </template>
</template>

<style lang="scss" module>
.footer {
  padding: 32px 24px 16px;
  background-color: var(--secondary-color-code);
  &__logo {
    display: block;
    object-fit: contain;
    max-height: 48px;
    margin: 0 auto 32px;
  }
  &__menu {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 32px;
    justify-content: space-around;
    padding: 0 64px;
    &__container {
      display: flex;
      align-items: center;
      color: #fff;
      text-decoration: none;
    }
    &__label {
      font-size: 14px;
      line-height: 1.14;
    }
    &__icon {
      position: relative;
      top: -1px;
      width: 16px;
      margin-left: 2px;
      flex-shrink: 0;
    }
  }
  &__copyright {
    display: block;
    margin-top: 24px;
    color: #fff;
    font-size: 10px;
    line-height: 1.6;
    text-align: center;
    white-space: pre-wrap;
  }
  @include mq() {
    padding: 40px 30px 24px;
    &__logo {
      max-height: 64px;
      margin-bottom: 40px;
    }
    &__menu {
      display: flex;
      flex-wrap: wrap;
      gap: 24px;
      justify-content: center;
      padding: 0;
    }
    &__copyright {
      margin-top: 32px;
      font-size: 12px;
      line-height: 1.33;
    }
  }
}
</style>
