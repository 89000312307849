<script setup lang="ts">
const styles = useCssModule()
const localePath = useLocalePath()

interface Breadcrumb {
  label: string
  link?: object
}
interface Props {
  items: Breadcrumb[]
}
const props = defineProps<Props>()
</script>

<template>
  <ol :class="styles.breadcrumb">
    <li
      v-for="(item, index) in props.items"
      :key="index"
      :class="styles.breadcrumb__item"
    >
      <div v-if="index >= 1" :class="styles.breadcrumb__icon">
        <KsIcon name="arrowRight" />
      </div>
      <NuxtLink
        v-if="item.link"
        :to="localePath(item.link)"
        :class="styles.breadcrumb__label"
      >
        {{ item.label }}
      </NuxtLink>
      <strong
        v-else-if="index === props.items.length - 1"
        :class="styles.breadcrumb__label"
        >{{ item.label }}</strong
      >
      <span v-else :class="styles.breadcrumb__label">{{ item.label }}</span>
    </li>
  </ol>
</template>

<style lang="scss" module>
.breadcrumb {
  display: flex;
  gap: 8px;
  width: 100%;
  max-width: $content-max-width;
  margin: auto auto 0;
  padding: 26px 24px;
  overflow-x: auto;
  a {
    color: inherit;
    text-decoration: underline;
    @media (hover: hover) {
      &:hover {
        text-decoration: none;
      }
    }
  }
  strong {
    font-weight: bold;
  }
  &__item {
    display: flex;
    align-items: center;
    white-space: nowrap;
  }
  &__icon {
    position: relative;
    top: 1px;
    width: 16px;
    margin-right: 8px;
  }
  &__label {
    font-size: 12px;
    line-height: 1;
  }
  @include mq() {
    display: flex;
    margin: 0 auto;
    padding: 21px 30px;
  }
}
</style>
