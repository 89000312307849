<script setup lang="ts">
import { useWindowSize } from '@vueuse/core'
import { useSettingForPageBuild } from '~/composables/useDataForPageBuild'

const styles = useCssModule()
const localePath = useLocalePath()
const { $breakpoint } = useNuxtApp()
const { width } = useWindowSize()
const { locale } = useI18n()

// マスター設定
const { data: settingData, error: settingError } =
  await useSettingForPageBuild()
if (settingError.value) {
  console.error(settingError.value)
  throw settingError.value
}
const setting = computed(() => settingData.value)

/**
 * レイアウト判定
 */
const isPC = computed(() => width.value >= $breakpoint)

/**
 * メニューの表示
 */
const menuRef = ref()
const isMenu = ref()

const hamburgerClass = computed(() => {
  const classes = [styles.header__hamburger]
  if (isMenu.value == null) {
    classes.push(styles['is-init'])
  } else if (isMenu.value) {
    classes.push(styles['is-open'])
  } else {
    classes.push(styles['is-close'])
  }
  return classes
})

const menuClass = computed(() => {
  const classes = [styles.header__menu]
  if (isMenu.value == null) {
    classes.push(styles['is-init'])
  } else if (isMenu.value) {
    classes.push(styles['is-open'])
  } else {
    classes.push(styles['is-close'])
  }
  return classes
})

const toggleMenu = () => {
  isMenu.value = !isMenu.value
}

const hideMenu = () => {
  if (isPC.value) return
  isMenu.value = false
}

onMounted(() => {
  menuRef.value.addEventListener('animationstart', () => {
    if (isMenu.value === true) {
      menuRef.value.style.transform = 'translateX(0)'
    }
  })
  menuRef.value.addEventListener('animationend', () => {
    if (isMenu.value === false) {
      menuRef.value.style.transform = 'translateX(-100%)'
    }
  })
})
onBeforeUnmount(() => {
  menuRef.value.removeEventListener('animationstart')
  menuRef.value.removeEventListener('animationend')
})

/**
 * 言語リストの表示
 */
const isLanguages = ref(false)
const toggleLanguage = () => {
  isLanguages.value = !isLanguages.value
}

/**
 * レイアウト変更時の初期化
 */
watch(isPC, (value) => {
  if (value) {
    menuRef.value.style.transform = 'translateX(0)'
  }
})
</script>

<template>
  <template v-if="setting">
    <header :class="styles.header">
      <p v-if="setting.templateLayout.headerLogo" :class="styles.header__name">
        <NuxtLink
          :to="setting.templateLayout.headerLogoLinkUrl"
          :target="
            setting.templateLayout.headerLogoLinkOpenInAnotherTab
              ? '_blank'
              : '_self'
          "
        >
          <img
            :src="setting.templateLayout.headerLogo"
            :alt="setting.companyName"
          />
        </NuxtLink>
      </p>
      <button type="button" :class="hamburgerClass" @click="toggleMenu">
        <span :class="styles.header__hamburger__line" />
      </button>
      <div ref="menuRef" :class="menuClass">
        <ul :class="styles.header__menu__items">
          <li
            v-for="(header, index) in setting.templateLayout.headers"
            :key="index"
            :class="styles.header__menu__item"
          >
            <NuxtLink
              :to="header.url"
              :class="styles.header__menu__container"
              @click="hideMenu"
            >
              <div :class="styles.header__menu__label">{{ header.label }}</div>
              <ClientOnly>
                <div :class="styles.header__menu__icon">
                  <KsIcon
                    name="arrowRight"
                    :color="isPC ? '#3A3B3F' : '#FFF'"
                  />
                </div>
              </ClientOnly>
            </NuxtLink>
          </li>
        </ul>
        <div
          v-if="setting.availableLanguages.length >= 2"
          :class="styles.header__multiLang"
        >
          <button
            type="button"
            :class="styles.header__multiLang__toggle"
            @click="toggleLanguage"
          >
            <ClientOnly>
              <span :class="styles.header__multiLang__toggle__icon">
                <KsIcon name="language" :color="isPC ? '#3A3B3F' : '#FFF'" />
              </span>
            </ClientOnly>
            <span :class="styles.header__multiLang__toggle__label">
              Languages
            </span>
            <ClientOnly>
              <span :class="styles.header__multiLang__toggle__arrow">
                <KsIcon name="arrowBottom" :color="isPC ? '#3A3B3F' : '#FFF'" />
              </span>
            </ClientOnly>
          </button>
          <div :class="styles.header__multiLang__container">
            <KsAccordion :show="isLanguages">
              <ul :class="styles.header__multiLang__items">
                <template
                  v-for="lang in setting.availableLanguages"
                  :key="lang"
                >
                  <li
                    v-if="lang !== locale"
                    :class="styles.header__multiLang__item"
                  >
                    <ClientOnly>
                      <NuxtLink
                        :to="localePath($route.fullPath, lang)"
                        @click="hideMenu"
                      >
                        {{ $t(`languages.${lang}`) }}
                      </NuxtLink>
                    </ClientOnly>
                  </li>
                </template>
              </ul>
            </KsAccordion>
          </div>
        </div>
      </div>
    </header>
  </template>
</template>

<style lang="scss" module>
@keyframes bar-top-close {
  0% {
    transform: translateY(9px) rotate(45deg);
  }
  50% {
    transform: translateY(9px) rotate(0);
  }
  100% {
    transform: translateY(0) rotate(0);
  }
}
@keyframes bar-top-open {
  0% {
    transform: translateY(0) rotate(0);
  }
  50% {
    transform: translateY(9px) rotate(0);
    background-color: #fff;
  }
  100% {
    transform: translateY(9px) rotate(45deg);
    background-color: #fff;
  }
}
@keyframes bar-bottom-close {
  0% {
    transform: translateY(-9px) rotate(0) rotate(-45deg);
  }
  50% {
    transform: translateY(-9px) rotate(0) rotate(0);
  }
  100% {
    transform: translateY(0) rotate(0);
  }
}
@keyframes bar-bottom-open {
  0% {
    transform: translateY(0) rotate(0);
  }
  50% {
    transform: translateY(-9px) rotate(0) rotate(0);
    background-color: #fff;
  }
  100% {
    transform: translateY(-9px) rotate(0) rotate(-45deg);
    background-color: #fff;
  }
}
@keyframes menu-close {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes menu-open {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.header {
  $root: #{&};
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  min-height: 72px;
  padding: 16px 24px;
  border-bottom: 1px solid #e7e7e7;
  &__name {
    img {
      width: auto;
      max-height: 48px;
      object-fit: contain;
    }
  }
  &__hamburger {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    z-index: 100;
    width: 24px;
    height: 19px;
    margin-left: auto;
    &__line,
    &::before,
    &::after {
      display: block;
      width: 100%;
      height: 1px;
      background-color: $font-color;
      animation-duration: 0.3s;
      animation-timing-function: ease;
      animation-delay: 0;
      animation-fill-mode: forwards;
    }
    &__line {
      transition: 0.3s;
    }
    &::before,
    &::after {
      content: '';
    }
    &.is-close {
      &::before {
        animation-name: bar-top-close;
      }
      &::after {
        animation-name: bar-bottom-close;
      }
    }
    &.is-open {
      #{$root}__hamburger__line {
        opacity: 0;
      }
      &::before {
        animation-name: bar-top-open;
      }
      &::after {
        animation-name: bar-bottom-open;
      }
    }
  }
  &__menu {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    width: 100vw;
    height: 100vh;
    padding: 70px 40px 40px;
    background-color: $font-color;
    transition: 0.3s opacity;
    animation-duration: 0.3s;
    animation-timing-function: ease;
    animation-delay: 0;
    animation-fill-mode: forwards;
    &.is-init {
      display: none;
    }
    &.is-close {
      animation-name: menu-close;
    }
    &.is-open {
      animation-name: menu-open;
      #{$root}__menu__items {
        opacity: 1;
        transform: translateY(0);
      }
    }
    a {
      color: #fff;
      text-decoration: none;
    }
    &__items {
      opacity: 0.5;
      transform: translateY(40px);
      transition: 0.45s;
    }
    &__item {
      &:not(:first-child) {
        margin-top: 16px;
      }
    }
    &__container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 15px 0;
    }
    &__label {
      font-size: 14px;
      line-height: 1.14;
    }
    &__icon {
      position: relative;
      top: -1px;
      width: 16px;
      min-width: 16px;
    }
  }
  &__multiLang {
    margin-top: 16px;
    padding: 16px 12px;
    border: 1px solid;
    border-radius: 4px;
    color: #fff;
    font-size: 12px;
    &__toggle {
      display: flex;
      align-items: center;
      gap: 4px;
      width: 100%;
      &__icon {
        width: 12px;
      }
      &__arrow {
        width: 16px;
        margin-left: auto;
      }
    }
    &__item {
      margin-top: 16px;
      a {
        width: 100%;
      }
    }
  }
  @include mq() {
    justify-content: space-between;
    min-height: 80px;
    padding: 16px 30px;
    &__name {
      img {
        max-height: 48px;
      }
    }
    &__hamburger {
      display: none;
    }
    &__menu {
      display: flex !important;
      align-items: center;
      opacity: 1 !important;
      position: static;
      width: auto;
      height: auto;
      margin-left: auto;
      padding: 0 0 0 8px;
      background-color: transparent;
      a {
        color: $font-color;
      }
      &__items {
        display: flex;
        opacity: 1;
        gap: 24px;
        transform: translateY(0);
      }
      &__item {
        &:not(:first-child) {
          margin-top: 0;
        }
      }
      &__container {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 0;
      }
    }
    &__multiLang {
      position: relative;
      margin-top: 0;
      margin-left: 32px;
      padding: 8px 8px 8px 12px;
      border: 1px solid #e7e7e7;
      background-color: #fff;
      color: inherit;
      &__container {
        position: absolute;
        width: calc(100% + 2px);
        left: -1px;
      }
      &__items {
        background: #fff;
        padding: 12px 12px;
        border: 1px solid #e7e7e7;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        border-top: 0;
      }
      &__item {
        margin-top: 12px;
        &:first-child {
          margin-top: 0;
        }
      }
    }
  }
}
</style>
