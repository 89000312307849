<script setup lang="ts">
import { useSettingForPageBuild } from '~/composables/useDataForPageBuild'
import { useBreadcrumb } from '~/composables/useBreadcrumb'
const styles = useCssModule()

/**
 * データ取得
 */
// マスター設定
const { data: settingData, error: settingError } =
  await useSettingForPageBuild()
if (settingError.value) {
  console.error(settingError.value)
  throw settingError.value
}
const setting = computed(() => settingData.value)

/**
 * useHead の設定
 */
const scripts = computed(() => {
  const scriptArray = []

  // Google Analytics
  if (setting.value?.googleAnalyticsPropertyId) {
    scriptArray.push(
      {
        src: `https://www.googletagmanager.com/gtag/js?id=${setting.value.googleAnalyticsPropertyId}`,
        tagPriority: 0,
        async: true,
      },
      {
        innerHTML: `
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', '${setting.value.googleAnalyticsPropertyId}');
        `,
        tagPriority: 1,
      },
    )
  }

  // テンプレート設定 -> Javascript
  if (setting.value?.layout.javascript) {
    scriptArray.push({
      innerHTML: setting.value.layout.javascript,
      tagPriority: 2,
    })
  }

  return scriptArray
})

const metas = computed(() => {
  const metaArray = []

  // Google Search Console
  if (setting.value?.searchConsoleVerificationCode) {
    metaArray.push({
      name: 'google-site-verification',
      content: setting.value.searchConsoleVerificationCode,
    })
  }
  return metaArray
})

const links = computed(() => {
  const linkArray = []

  // favicon
  if (setting.value?.faviconFileName) {
    const { faviconFileName } = setting.value
    switch (faviconFileName) {
      case 'favicon.ico':
        linkArray.push({
          rel: 'icon',
          href: `${setting.value.siteUrl}${setting.value.faviconFileName}`,
        })
        break
      case 'favicon.png':
        linkArray.push({
          rel: 'icon',
          type: 'image/png',
          href: `${setting.value.siteUrl}${setting.value.faviconFileName}`,
        })
        break
    }
  }
  return linkArray
})

const stylesHead = computed(() => {
  const styleArray = []

  styleArray.push({
    children: `:root {
        --primary-color-code: ${`#${setting.value?.layout.primaryColorCode ?? 'E9446A'}`};
        --secondary-color-code: ${`#${setting.value?.layout.secondaryColorCode ?? '3A3B3F'}`};
        --tertiary-color-code: ${`#${setting.value?.layout.tertiaryColorCode ?? '777'}`};
      }
      ${setting.value?.layout.headerCss ?? ''}
      ${setting.value?.layout.footerCss ?? ''}`,
  })
  return styleArray
})

// 更新
useHead({
  style: stylesHead,
  script: scripts,
  meta: metas,
  link: links,
})

/**
 * パンくずリスト
 */
const { breadcrumb } = useBreadcrumb()
const breadcrumbRef = ref()

/**
 * ページトップ関連の処理
 */
const observerOptions = {
  root: null,
  rootMargin: '0px',
  threshold: 0,
}
const headerRef = ref<HTMLDivElement>()
const headerObserver = ref<IntersectionObserver>()
const footerRef = ref<HTMLDivElement>()
const footerObserver = ref<IntersectionObserver>()
const pageTopOpacity = ref(0)
const pageTopPosition = ref<'fixed' | 'absolute'>('fixed')
const pageTopStyle = computed(() => ({
  opacity: pageTopOpacity.value,
  position: pageTopPosition.value,
}))

onMounted(() => {
  // 表示/非表示の切り替え
  headerObserver.value = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        pageTopOpacity.value = 0
      } else {
        pageTopOpacity.value = 1
      }
    })
  }, observerOptions)

  // 位置調整
  footerObserver.value = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        pageTopPosition.value = 'absolute'
      } else {
        pageTopPosition.value = 'fixed'
      }
    })
  }, observerOptions)
})

// オブザーブ実行
watch(headerRef, () => {
  if (headerRef.value && headerObserver.value) {
    headerObserver.value.observe(headerRef.value)
  }
})
watch(footerRef, () => {
  if (footerRef.value && footerObserver.value) {
    footerObserver.value.observe(footerRef.value)
  }
})

// オブザーバー解放
onBeforeUnmount(() => {
  if (headerObserver.value) headerObserver.value.disconnect()
  if (footerObserver.value) footerObserver.value.disconnect()
})
</script>

<template>
  <div :class="styles.container">
    <div ref="headerRef">
      <div
        v-if="setting?.layout.headerHtml"
        :class="styles.header"
        v-html="setting.layout.headerHtml"
      />
      <TheHeader v-else />
    </div>
    <main :class="styles.content">
      <div ref="breadcrumbRef" :class="styles.breadcrumb">
        <KsBreadcrumb :items="breadcrumb" />
      </div>
      <slot />
      <a href="#" :class="styles.pageTop" :style="pageTopStyle">
        <KsIcon name="arrowTop" color="#FFF" />
      </a>
    </main>
    <div ref="footerRef">
      <div
        v-if="setting?.layout.footerHtml"
        :class="styles.footer"
        v-html="setting.layout.footerHtml"
      />
      <TheFooter v-else />
    </div>
  </div>
</template>

<style lang="scss" module>
.container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
}

.breadcrumb {
  order: 1;

  @include mq() {
    order: 0;
  }
}

.pageTop {
  opacity: 0;
  position: fixed;
  right: 24px;
  bottom: 16px;
  z-index: 1;
  width: 56px;
  height: 56px;
  padding: 12px;
  border-radius: 50%;
  background-color: var(--secondary-color-code);
  transition: opacity 0.3s;
  @include mq() {
    right: 32px;
    bottom: 32px;
  }
}
</style>
